import React, { useEffect, useState } from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import FilterToggle from "components/FilterToggle";
import FilterSelected from "components/FilterSelected";

//Styles
import styles from "./FilterContainer.module.scss";

//Types
import { WrapperData } from "types/wrapperData";
import { FilterResultWrapper } from "types/filterResultWrapper";
import { FilterDto } from "types/filterDto";

//Hooks
import { useIsSmallDevice } from "hooks";

interface Props {
  paramCadence?: string;
  paramOffering?: string;
  wrapperData?: WrapperData;
  releaseNotes?: FilterResultWrapper[];
  handleSelection: (
    offerings?: string[],
    categories?: string[],
    releaseNotesTypes?: string[],
    cadences?: string[]
  ) => void;
  handleFilters: (filterDto: FilterDto) => void;
  handleResetFilters: () => void;
  searchByFilters: boolean;
  selectedOfferings: string[];
  selectedCategories: string[];
  selectedReleaseNoteTypes: string[];
  selectedCadences: string[];
  offeringsAfterFiltering: string[];
  categoriesAfterFiltering: string[];
  releaseNoteTypesAfterFiltering: string[];
  cadencesAfterFiltering: string[];
  expandFilter: boolean;
  handleExpandFilter: () => void;
  range?: string;
  rangeFrom?: string;
  rangeTo?: string;
  paramOfferings?: string[];
  paramCategories?: string[];
  paramTypes?: string[];
  paramCadences?: string[];
  hasFilterChanged: (filterChanged: boolean) => void;
}

const FilterContainer = ({
  paramCadence,
  paramOffering,
  wrapperData,
  releaseNotes,
  handleSelection,
  handleFilters,
  handleResetFilters,
  searchByFilters,
  selectedOfferings,
  selectedCategories,
  selectedReleaseNoteTypes,
  selectedCadences,
  offeringsAfterFiltering,
  categoriesAfterFiltering,
  releaseNoteTypesAfterFiltering,
  cadencesAfterFiltering,
  expandFilter,
  handleExpandFilter,
  range,
  rangeFrom,
  rangeTo,
  paramOfferings,
  paramCategories,
  paramTypes,
  paramCadences,
  hasFilterChanged,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  // selectedOfferings/selectedCategories props might have been updated from the parent. So update them accordingly.
  const [offerings, setOfferings] = useState(selectedOfferings);
  useEffect(() => {
    setOfferings(selectedOfferings);
  }, [selectedOfferings]);
  const [categories, setCategories] = useState(selectedCategories);
  useEffect(() => {
    setCategories(selectedCategories);
  }, [selectedCategories]);

  // releaseNotes might have been updated from the parent. Update it accordingly
  const [updatedReleaseNotes, setUpdatedReleaseNotes] = useState(releaseNotes);
  useEffect(() => {
    setUpdatedReleaseNotes(releaseNotes);
  }, [releaseNotes]);

  return (
    <>
      <div
        className={`${styles.filters} ${
          isSmallDevice ? styles.smallDevicePadding : styles.padding
        }`}
      >
        <div
          className={`${
            isSmallDevice ? styles.smallDeviceTitle : styles.title
          }`}
        >
          {t("releasenotes-gui-icu.releasenotes.first_subtitle.msg")}
        </div>
        <div
          className={`${
            isSmallDevice ? styles.smallDeviceSubtitle : styles.subtitle
          }`}
        >
          {t("releasenotes-gui-icu.releasenotes.second_subtitle.msg")}
        </div>
        <FilterToggle
          paramCadence={paramCadence}
          paramOffering={paramCadence}
          wrapperData={wrapperData}
          handleResetFilters={handleResetFilters}
          handleFilters={handleFilters}
          handleSelection={handleSelection}
          releaseNotes={updatedReleaseNotes}
          selectedOfferings={offerings}
          selectedCategories={categories}
          selectedReleaseNoteTypes={selectedReleaseNoteTypes}
          selectedCadences={selectedCadences}
          expandFilter={expandFilter}
          handleExpandFilter={handleExpandFilter}
          range={range}
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          hasFilterChanged={hasFilterChanged}
        ></FilterToggle>
      </div>
      <FilterSelected
        wrapperData={wrapperData}
        releaseNotes={releaseNotes}
        searchByFilters={searchByFilters}
        offeringsAfterFiltering={offeringsAfterFiltering}
        categoriesAfterFiltering={categoriesAfterFiltering}
        releaseNoteTypesAfterFiltering={releaseNoteTypesAfterFiltering}
        cadencesAfterFiltering={cadencesAfterFiltering}
        paramOffering={paramOffering}
        paramCadence={paramCadence}
        paramOfferings={paramOfferings}
        paramCategories={paramCategories}
        paramTypes={paramTypes}
        paramCadences={paramCadences}
      ></FilterSelected>
    </>
  );
};

export default FilterContainer;
