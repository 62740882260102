import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";

//Components
import FilterCadenceList from "components/FilterCadenceRadioButtonList";
import FilterCadenceRange from "components/FilterCadenceRange";

//Util
import {
  FILTER_LTS_INDIVIDUAL,
  FILTER_STABLE_INDIVIDUAL,
  FILTER_LTS_RANGE,
  FILTER_STABLE_RANGE,
} from "util/constants";

//Types
import { WrapperData } from "types/wrapperData";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./FilterCadencePanel.module.scss";

interface Props {
  paramCadence?: string;
  wrapperData?: WrapperData;
  selectedCadences: string[];
  handleChange: (codeText: string, type: string, fromOrTo?: string) => void;
  selectedCadenceRange?: string;
  selectedCadenceRangeFrom?: string;
  selectedCadenceRangeTo?: string;
}

const FilterCadencePanel = ({
  paramCadence,
  wrapperData,
  selectedCadences,
  handleChange,
  selectedCadenceRange,
  selectedCadenceRangeFrom,
  selectedCadenceRangeTo,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  return (
    <>
      <Card className={isSmallDevice ? styles.smallDevicePanel : styles.panel}>
        <div className={styles.cadenceDiv}>
          <div className={styles.sectionTitle}>
            {t("releasenotes-gui-icu.releasenotes.filter_versions.msg")}
          </div>
          <div className={styles.subTitle}>
            <span className={styles.labelCadence}>
              {t("releasenotes-gui-icu.releasenotes.filter_cadence.msg")}
            </span>
            <span className={styles.labelCadenceDesc}>
              {t("releasenotes-gui-icu.releasenotes.filter_version_lts.msg")}
            </span>
          </div>
          <div className={styles.cadenceListDiv}>
            <FilterCadenceList
              paramCadence={paramCadence}
              selectedCadences={selectedCadences}
              versions={wrapperData?.ltsVersions}
              typeOfCadence={FILTER_LTS_INDIVIDUAL}
              handleChange={handleChange}
            ></FilterCadenceList>
            <FilterCadenceRange
              selectedCadences={selectedCadences}
              versions={wrapperData?.ltsVersions}
              typeOfCadence={FILTER_LTS_RANGE}
              handleChange={handleChange}
              selectedCadenceRange={selectedCadenceRange}
              selectedCadenceRangeFrom={selectedCadenceRangeFrom}
              selectedCadenceRangeTo={selectedCadenceRangeTo}
            ></FilterCadenceRange>
          </div>
          <div className={styles.space}></div>
          <div className={styles.subTitle}>
            <span className={styles.labelCadence}>
              {t("releasenotes-gui-icu.releasenotes.filter_cadence.msg")}
            </span>
            <span className={styles.labelCadenceDesc}>
              {t("releasenotes-gui-icu.releasenotes.filter_version_stable.msg")}
            </span>
          </div>
          <div className={styles.cadenceListDiv}>
            <FilterCadenceList
              paramCadence={paramCadence}
              selectedCadences={selectedCadences}
              versions={wrapperData?.stableVersions}
              typeOfCadence={FILTER_STABLE_INDIVIDUAL}
              handleChange={handleChange}
            ></FilterCadenceList>
            <FilterCadenceRange
              selectedCadences={selectedCadences}
              versions={wrapperData?.stableVersions}
              typeOfCadence={FILTER_STABLE_RANGE}
              handleChange={handleChange}
              selectedCadenceRange={selectedCadenceRange}
              selectedCadenceRangeFrom={selectedCadenceRangeFrom}
              selectedCadenceRangeTo={selectedCadenceRangeTo}
            ></FilterCadenceRange>
          </div>
        </div>
      </Card>
    </>
  );
};

export default FilterCadencePanel;
