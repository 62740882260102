//import React from 'react'

//Styles
import styles from "./VerticalDivider.module.scss";

const VerticalDivider = () => {
  return <div className={styles.verticalDivider} />;
};

export default VerticalDivider;
