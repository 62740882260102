import React from "react";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./RadioButton.module.scss";

interface Props {
  id: string;
  label: any;
  title?: string;
  typeOfCadence: string;
  disabled: boolean;
  isChecked: boolean;
  handleChange: (codeText: string, type: string) => void;
}

const RadioButton = ({
  id,
  label,
  title,
  typeOfCadence,
  disabled,
  isChecked,
  handleChange,
}: Props) => {
  const isSmallDevice = useIsSmallDevice();

  function handleRadio() {
    handleChange(id, typeOfCadence);
  }
  //13: Enter key, 32: Space key
  function keypressHandler(e: React.KeyboardEvent) {
    if (e.keyCode !== 9) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleChange(id, typeOfCadence);
    }
  }

  return (
    <div className={isSmallDevice ? styles.smallDeviceField : styles.field}>
      <div
        tabIndex={0}
        onKeyDown={keypressHandler}
        title={title}
        aria-labelledby={id + "-label"}
        role="radio"
        aria-checked={isChecked}
      >
        {disabled ? (
          <span className={styles.disabledRadio}></span>
        ) : (
          <input
            type="radio"
            id={id}
            className={disabled ? styles.disabledRadio : styles.radio}
            value={id}
            name="cadence"
            checked={isChecked}
            onClick={handleRadio}
            onChange={handleRadio}
            onKeyDown={keypressHandler}
          />
        )}
        <span
          onClick={handleRadio}
          className={styles.radioButton}
          aria-label="radio button"
        ></span>
      </div>
      <span>
        <label
          id={id + "-label"}
          className={disabled ? styles.radioLabelDisabled : styles.radioLabel}
          onClick={handleRadio}
        >
          {label}
        </label>
      </span>
    </div>
  );
};

export default RadioButton;
