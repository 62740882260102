import React, { useEffect, useState } from "react";

//Node Modules
import { useTranslation } from "react-i18next";
import { Card, Image } from "react-bootstrap";

//Components
import FilterOfferingList from "components/FilterOfferingList";
import FilterCategoryList from "components/FilterCategoryList";
import LinkButton from "components/LinkButton";
import HorizontalDivider from "components/HorizontalDivider";

//Types
import { WrapperData } from "types/wrapperData";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./FilterOfferingCategoryPanel.module.scss";

interface Props {
  paramCadence?: string;
  wrapperData?: WrapperData;
  selectAll: (select: boolean) => void;
  selectedOfferings: string[];
  selectedCategories: string[];
  handleChange: (codeText: string, type: string) => void;
}

const FilterOfferingCategoryPanel = ({
  paramCadence,
  wrapperData,
  selectAll,
  selectedOfferings,
  selectedCategories,
  handleChange,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  // selectedOfferings/selectedCategories props might have been updated from the parent. So update them accordingly.
  const [offerings, setOfferings] = useState(selectedOfferings);
  useEffect(() => {
    setOfferings(selectedOfferings);
  }, [selectedOfferings]);
  const [categories, setCategories] = useState(selectedCategories);
  useEffect(() => {
    setCategories(selectedCategories);
  }, [selectedCategories]);

  function select() {
    selectAll(true);
  }
  function unselect() {
    selectAll(false);
  }
  function keypressHandler(e: React.KeyboardEvent) {
    //13: Enter key, 32: Space key
    if (e.keyCode === 13 || e.keyCode === 32) {
      let target = e.target as HTMLElement;
      if (
        target.textContent ===
        t("releasenotes-gui-icu.releasenotes.select_all.msg")
      ) {
        selectAll(true);
      }
      if (
        target.textContent ===
        t("releasenotes-gui-icu.releasenotes.clear_all.msg")
      ) {
        selectAll(false);
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (
    <>
      <Card className={isSmallDevice ? styles.smallDevice : styles.leftPanel}>
        <div className={styles.offeringHeader}>
          <span className={styles.offeringTitle}>
            {" "}
            {t("releasenotes-gui-icu.releasenotes.filter_offerings.msg")}
          </span>
          <div className={styles.selectAllClearAll}>
            <LinkButton
              className={styles.linkButton}
              onClick={select}
              onKeyDown={keypressHandler}
            >
              <Image
                className={styles.imageCheck}
                src="images/checkAll.svg"
                onClick={select}
                onKeyDown={keypressHandler}
              />
              <span className={styles.linkButtonLabel}>
                {t("releasenotes-gui-icu.releasenotes.select_all.msg")}
              </span>
            </LinkButton>
            {isSmallDevice ? (
              ""
            ) : (
              <span className={styles.verticalDivider}></span>
            )}
            <LinkButton
              className={styles.linkButton}
              onClick={unselect}
              onKeyDown={keypressHandler}
            >
              <Image
                className={styles.imageCheck}
                src="images/uncheckAll.svg"
                onClick={unselect}
                onKeyDown={keypressHandler}
              />
              <span className={styles.linkButtonLabel}>
                {t("releasenotes-gui-icu.releasenotes.clear_all.msg")}
              </span>
            </LinkButton>
          </div>
        </div>
        <div className={styles.subTitle}>
          {t("releasenotes-gui-icu.releasenotes.filter_product.msg")}
        </div>
        <div className={styles.offeringListDiv}>
          <FilterOfferingList
            wrapperData={wrapperData}
            selectedOfferings={offerings}
            handleChange={handleChange}
          ></FilterOfferingList>
        </div>
        <div>
          <HorizontalDivider />
        </div>
        <div className={styles.subTitle}>
          {t("releasenotes-gui-icu.releasenotes.filter_category.msg")}
        </div>
        <div className={styles.categoryListDiv}>
          <FilterCategoryList
            paramCadence={paramCadence}
            wrapperData={wrapperData}
            selectedCategories={categories}
            handleChange={handleChange}
          ></FilterCategoryList>
        </div>
      </Card>
    </>
  );
};

export default FilterOfferingCategoryPanel;
