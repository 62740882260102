import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";
import _ from "lodash";

//Components
import HorizontalDivider from "components/HorizontalDivider";
import ShareUrl from "components/ShareUrl";

//Types
import { WrapperData } from "types/wrapperData";
import { FilterResultWrapper } from "types/filterResultWrapper";

//Hooks
import { useIsSmallDevice } from "hooks";

//Util
import {
  CATEGORY_PROGRAMMING,
  CATEGORY_DEPLOYMENT,
  CATEGORY_ADMINISTRATION,
  TYPE_DEPRECATED,
} from "util/constants";
import {
  TYPE_WHATSNEW,
  TYPE_DEPLOYMENTNOTES,
  PRODUCT_DISPLAY_LIMIT,
} from "util/constants";

//Styles
import styles from "./FilterSelected.module.scss";

interface Props {
  wrapperData?: WrapperData;
  releaseNotes?: FilterResultWrapper[];
  searchByFilters: boolean;
  offeringsAfterFiltering: string[];
  categoriesAfterFiltering: string[];
  releaseNoteTypesAfterFiltering: string[];
  cadencesAfterFiltering: string[];
  paramOffering?: string;
  paramCadence?: string;
  paramOfferings?: string[];
  paramCategories?: string[];
  paramTypes?: string[];
  paramCadences?: string[];
}

const FilterSelected = ({
  wrapperData,
  releaseNotes,
  searchByFilters,
  offeringsAfterFiltering,
  categoriesAfterFiltering,
  releaseNoteTypesAfterFiltering,
  cadencesAfterFiltering,
  paramOffering,
  paramCadence,
  paramOfferings,
  paramCategories,
  paramTypes,
  paramCadences,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  const getCategories = () => {
    let categories = new Array<string>();
    if (searchByFilters) {
      categories = _.cloneDeep(categoriesAfterFiltering);
    } else if (paramCategories) {
      categories = _.cloneDeep(paramCategories);
    } else if (
      releaseNotes !== undefined &&
      releaseNotes.length > 0 &&
      wrapperData !== undefined
    ) {
      wrapperData.categories.forEach((item) => {
        categories.push(item.codeText);
      });
    }
    return categories;
  };

  const getReleaseNoteTypes = () => {
    let releaseNoteTypes = new Array<string>();
    if (searchByFilters) {
      releaseNoteTypes = _.cloneDeep(releaseNoteTypesAfterFiltering);
    } else if (paramTypes) {
      releaseNoteTypes = _.cloneDeep(paramTypes);
    } else if (
      releaseNotes !== undefined &&
      releaseNotes.length > 0 &&
      wrapperData !== undefined
    ) {
      wrapperData.releaseNoteTypes.forEach((item) => {
        releaseNoteTypes.push(item.codeText);
      });
    }
    return releaseNoteTypes;
  };

  const findOfferingInArray = (codeText: string) => {
    let foundItem = wrapperData?.offerings.filter(
      (e) => e.codeText === codeText
    );
    return foundItem ? foundItem[0]?.displayValue : "";
  };

  const getOfferingDisplayValues = () => {
    let offeringDisplayValues = new Array<string>();

    //After Apply Filters is clicked
    if (searchByFilters) {
      offeringsAfterFiltering.forEach((codeText) => {
        let displayValue = findOfferingInArray(codeText);
        if (displayValue !== "") {
          offeringDisplayValues.push(displayValue);
        }
      });
    }
    //Initial load, check to to see paramOffering is a valid offering, display it if so.
    else if (
      paramOffering &&
      wrapperData?.offerings.filter((e) => e.codeText === paramOffering)
    ) {
      let displayValue = findOfferingInArray(paramOffering);
      if (displayValue !== "") {
        offeringDisplayValues.push(displayValue);
      }
    }
    // If a list of offerings are passed in URL parameters
    else if (paramOfferings) {
      paramOfferings.forEach((offering) => {
        let displayValue = findOfferingInArray(offering);
        if (displayValue !== "") {
          offeringDisplayValues.push(displayValue);
        }
      });
    }
    //Initial load, if releaseNotes is not null (retrieved by cadence), get products from it
    else if (releaseNotes !== undefined && releaseNotes.length > 0) {
      releaseNotes[0].offeringReleaseNotes.forEach((entry) => {
        let displayValue = findOfferingInArray(entry.offeringCodeText);
        if (displayValue !== "" && displayValue !== undefined) {
          offeringDisplayValues.push(displayValue);
        }
      });
    }
    return offeringDisplayValues;
  };

  let categories = getCategories();
  let totalOfofferings = getOfferingDisplayValues();
  //Only select the first a few offering
  let offerings = totalOfofferings.slice(
    0,
    PRODUCT_DISPLAY_LIMIT - categories.length
  );
  let releaseNoteTypes = getReleaseNoteTypes();

  const getCadenceDisplayValue = () => {
    let item;
    // Filtering single cadence
    if (
      cadencesAfterFiltering !== undefined &&
      cadencesAfterFiltering.length === 1
    ) {
      item = cadencesAfterFiltering[0];
      let found = findCadenceInArray(cadencesAfterFiltering[0]);
      item = found ? found[0]?.codeText : "";
    }
    // Filtering cadence range
    else if (
      cadencesAfterFiltering !== undefined &&
      cadencesAfterFiltering.length >= 1
    ) {
      let to = findCadenceInArray(cadencesAfterFiltering[0]);
      let from = findCadenceInArray(
        cadencesAfterFiltering[cadencesAfterFiltering.length - 1]
      );
      item =
        (from ? from[0]?.codeText : "") + " - " + (to ? to[0]?.codeText : "");
    }
    // One cadence is passed in "cadences" URL parameters
    else if (paramCadences !== undefined && paramCadences.length === 1) {
      item = paramCadences[0];
      let found = findCadenceInArray(paramCadences[0]);
      item = found ? found[0]?.codeText : "";
    }
    // A list of cadences passed in URL parameters
    else if (paramCadences !== undefined && paramCadences.length > 1) {
      let to = findCadenceInArray(paramCadences[0]);
      let from = findCadenceInArray(paramCadences[paramCadences.length - 1]);
      item =
        (from ? from[0]?.codeText : "") + " - " + (to ? to[0]?.codeText : "");
    }
    //If cadencesAfterFiltering doesn't have any items, check to to see paramCadence is a valid cadence, display it if so.
    else if (paramCadence) {
      let found = findCadenceInArray(paramCadence);
      item = found ? found[0]?.codeText : "";
    }
    return item;
  };

  const findCadenceInArray = (codeText: string) => {
    let item;
    let found = wrapperData?.ltsVersions.filter((e) => e.codeText === codeText);
    if (!found || found?.length === 0) {
      item = wrapperData?.stableVersions.filter((e) => e.codeText === codeText);
    } else {
      item = found;
    }
    return item;
  };

  const getDisplayValue = (codeText: string, type: string) => {
    let item, foundItem;
    if (type === "category") {
      foundItem = wrapperData?.categories.filter(
        (e) => e.codeText === codeText
      );
    }
    if (type === "releaseNoteType") {
      foundItem = wrapperData?.releaseNoteTypes.filter(
        (e) => e.codeText === codeText
      );
    }
    item = foundItem ? foundItem[0]?.displayValue : "";
    return item;
  };

  return (
    <>
      {
        <div
          className={
            isSmallDevice
              ? styles.selectedFiltersSmallDevice
              : styles.selectedFilters
          }
        >
          {categories?.length + offerings?.length > 0 ? (
            <div>
              <label className={styles.levelOneTokenLabel}>
                {t(
                  "releasenotes-gui-icu.releasenotes.selected_release_notes_for.msg"
                )}
              </label>

              <ShareUrl></ShareUrl>
            </div>
          ) : (
            ""
          )}
          <div>
            {categories.map((category) => {
              switch (category) {
                case CATEGORY_PROGRAMMING: {
                  return (
                    <label key={category} className={styles.levelOneToken}>
                      {t(
                        "releasenotes-gui-icu.releasenotes.filter_category_programming.msg"
                      )}
                    </label>
                  );
                }
                case CATEGORY_DEPLOYMENT: {
                  return (
                    <label key={category} className={styles.levelOneToken}>
                      {t(
                        "releasenotes-gui-icu.releasenotes.filter_category_deployment.msg"
                      )}
                    </label>
                  );
                }
                case CATEGORY_ADMINISTRATION: {
                  return (
                    <label key={category} className={styles.levelOneToken}>
                      {t(
                        "releasenotes-gui-icu.releasenotes.filter_category_administration.msg"
                      )}
                    </label>
                  );
                }
                default: {
                  return "";
                }
              }
            })}
            {offerings.map((offering) => {
              return offering ? (
                <label className={styles.levelOneToken} key={offering}>
                  {offering}
                </label>
              ) : (
                ""
              );
            })}
            {categories.length + totalOfofferings.length >
            PRODUCT_DISPLAY_LIMIT ? (
              <label className={styles.levelOneToken}>
                {t("releasenotes-gui-icu.releasenotes.selected_more.msg")} (
                {categories.length +
                  totalOfofferings.length -
                  PRODUCT_DISPLAY_LIMIT}
                )
              </label>
            ) : (
              ""
            )}
          </div>
          {categories?.length + offerings?.length > 0 ? (
            <div className={styles.levelTwo}>
              <label className={styles.levelTwoTokenLabel}>
                {t(
                  "releasenotes-gui-icu.releasenotes.selected_filtered_by.msg"
                )}
              </label>
            </div>
          ) : (
            ""
          )}

          {getCadenceDisplayValue() ? (
            <label className={styles.levelTwoToken}>
              {getCadenceDisplayValue()}
            </label>
          ) : (
            ""
          )}

          {/* Selected Release Note Types  */}
          {releaseNoteTypes.map((codeText) => {
            switch (codeText) {
              case TYPE_WHATSNEW: {
                return (
                  <label key={codeText} className={styles.levelTwoToken}>
                    {t(
                      "releasenotes-gui-icu.releasenotes.filter_type_whatsnew.msg"
                    )}
                  </label>
                );
              }
              case TYPE_DEPRECATED: {
                return (
                  <label key={codeText} className={styles.levelTwoToken}>
                    {t(
                      "releasenotes-gui-icu.releasenotes.filter_type_critical_and_deprecated.msg"
                    )}
                  </label>
                );
              }
              case TYPE_DEPLOYMENTNOTES: {
                return (
                  <label key={codeText} className={styles.levelTwoToken}>
                    {t(
                      "releasenotes-gui-icu.releasenotes.filter_type_deployment_note.msg"
                    )}
                  </label>
                );
              }
              default: {
                return "";
              }
            }
          })}

          {isSmallDevice &&
          releaseNotes !== undefined &&
          releaseNotes?.length > 0 ? (
            <HorizontalDivider />
          ) : (
            ""
          )}
        </div>
      }
    </>
  );
};

export default FilterSelected;
