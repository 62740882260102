import React, { useState, useEffect } from "react";

//Node Modules
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Card } from "react-bootstrap";

//Components
import FilterOfferingCategoryPanel from "components/FilterOfferingCategoryPanel";
import FilterCadencePanel from "components/FilterCadencePanel";
import FilterReleaseNoteTypePanel from "components/FilterReleaseNoteTypePanel";
import FilterApplyResetPanel from "components/FilterApplyResetPanel";
import FilterToggleSmallDevice from "components/FilterToggleSmallDevice";
import HorizontalDivider from "components/HorizontalDivider";
import VerticalDivider from "components/VerticalDivider";

//Types
import { WrapperData, Cadence } from "types/wrapperData";
import { FilterDto } from "types/filterDto";
import { FilterResultWrapper } from "types/filterResultWrapper";

//Util
import {
  FILTER_LTS,
  FILTER_STABLE,
  FILTER_LTS_INDIVIDUAL,
  FILTER_STABLE_INDIVIDUAL,
  FILTER_LTS_RANGE,
  FILTER_STABLE_RANGE,
  FILTER_RAGNGE,
  FILTER_FROM,
  FILTER_TO,
  TYPE_WHATSNEW,
  TYPE_DEPRECATED,
} from "util/constants";
import { OFFERING, CATEGORY, RELEASE_NOTE_TYPE } from "util/constants";

//Hooks
import { useIsSmallDevice } from "hooks";

//Context
import {
  SelectedCadenceRangeContext,
  SelectedCadenceRangeFromContext,
  SelectedCadenceRangeToContext,
} from "context/cadence-range-context";
import { SelectedReleaseNoteTypesContext } from "context/selected-filters";

//Styles
import styles from "./FilterToggle.module.scss";

interface Props {
  paramCadence?: string;
  paramOffering?: string;
  wrapperData?: WrapperData;
  handleFilters: (filterDto: FilterDto) => void;
  handleSelection: (
    offerings?: string[],
    categories?: string[],
    releaseNotesTypes?: string[],
    cadences?: string[]
  ) => void;
  handleResetFilters: () => void;
  releaseNotes?: FilterResultWrapper[];
  selectedOfferings: string[];
  selectedCategories: string[];
  selectedReleaseNoteTypes: string[];
  selectedCadences: string[];
  expandFilter: boolean;
  handleExpandFilter: () => void;
  range?: string;
  rangeFrom?: string;
  rangeTo?: string;
  hasFilterChanged: (filterChanged: boolean) => void;
}

const FilterToggle = ({
  paramCadence,
  paramOffering,
  wrapperData,
  handleFilters,
  handleSelection,
  handleResetFilters,
  releaseNotes,
  selectedOfferings,
  selectedCategories,
  selectedReleaseNoteTypes,
  selectedCadences,
  expandFilter,
  handleExpandFilter,
  range,
  rangeFrom,
  rangeTo,
  hasFilterChanged,
}: Props) => {
  const { t } = useTranslation();
  const [filterApplied, setFilterApplied] = useState(false);
  const [selectedCadenceRangeFrom, setSelectedCadenceRangeFrom] =
    useState<string>();
  const [selectedCadenceRangeTo, setSelectedCadenceRangeTo] =
    useState<string>();
  const [selectedCadenceRange, setSelectedCadenceRange] = useState<string>();
  const [showSmallDeviceFilterContainer, setShowSmallDeviceFilterContainer] =
    useState(false);
  const isSmallDevice = useIsSmallDevice();

  // selectedOfferings/selectedCategories props might have been updated from the parent. So update them accordingly.
  const [offerings, setOfferings] = useState(selectedOfferings);
  useEffect(() => {
    setOfferings(selectedOfferings);
  }, [selectedOfferings]);
  const [categories, setCategories] = useState(selectedCategories);
  useEffect(() => {
    setCategories(selectedCategories);
  }, [selectedCategories]);

  useEffect(() => {
    if (range) {
      setSelectedCadenceRange(range);
    }
  }, [range]);
  useEffect(() => {
    if (rangeFrom) {
      setSelectedCadenceRangeFrom(rangeFrom);
    }
  }, [rangeFrom]);
  useEffect(() => {
    if (rangeTo) {
      setSelectedCadenceRangeTo(rangeTo);
    }
  }, [rangeTo]);

  // releaseNotes might have been updated from the parent. Update it accordingly
  const [updatedReleaseNotes, setUpdatedReleaseNotes] = useState(releaseNotes);
  useEffect(() => {
    setUpdatedReleaseNotes(releaseNotes);
  }, [releaseNotes]);

  const selectAll = (select: boolean) => {
    if (select) {
      let offerings = wrapperData
        ? wrapperData.offerings.map((offering) => offering.codeText)
        : [];
      let categories = wrapperData
        ? wrapperData.categories.map((category) => category.codeText)
        : [];
      handleSelection(offerings, categories, undefined, undefined);
    } else {
      handleSelection([], [], undefined, undefined);
    }
  };

  const handleChange = (codeText: string, type: string, fromOrTo?: string) => {
    // Detect changes in fitlers so that disable/enable ShareUrl button accordingly
    hasFilterChanged(true);

    if (type === OFFERING) {
      let offerings = _.cloneDeep(selectedOfferings);
      if (offerings.includes(codeText)) {
        offerings = offerings.filter((e) => e !== codeText);
      } else {
        offerings.push(codeText);
      }
      handleSelection(offerings);
    } else if (type === CATEGORY) {
      let categories = _.cloneDeep(selectedCategories);
      if (categories.includes(codeText)) {
        categories = categories.filter((e) => e !== codeText);
      } else {
        categories.push(codeText);
      }
      handleSelection(undefined, categories);
    } else if (type === RELEASE_NOTE_TYPE) {
      let releaseNoteTypes = _.cloneDeep(selectedReleaseNoteTypes);
      // Previously the type is checked, now uncheck, so remove it from the selected list
      if (releaseNoteTypes.includes(codeText)) {
        releaseNoteTypes = releaseNoteTypes.filter((e) => e !== codeText);
        // When whatsNew is unchecked, uncheck deprecated too
        if (codeText === TYPE_WHATSNEW) {
          releaseNoteTypes = releaseNoteTypes.filter(
            (e) => e !== TYPE_DEPRECATED
          );
        }
        // When deprecated is unchecked, uncheck whatsNew too
        if (codeText === TYPE_DEPRECATED) {
          releaseNoteTypes = releaseNoteTypes.filter(
            (e) => e !== TYPE_WHATSNEW
          );
        }
      }
      // Previously the type is unchecked, now check, so add it into the selected list
      else {
        releaseNoteTypes.push(codeText);
        // When whatsNew is checked, check deprecated too
        if (codeText === TYPE_WHATSNEW) {
          if (!releaseNoteTypes.includes(TYPE_DEPRECATED)) {
            releaseNoteTypes.push(TYPE_DEPRECATED);
          }
        }
      }
      handleSelection(undefined, undefined, releaseNoteTypes);
    }
    // Individual cadence selected
    else if (
      type === FILTER_LTS_INDIVIDUAL ||
      type === FILTER_STABLE_INDIVIDUAL
    ) {
      let cadences = [];
      cadences.push(codeText);
      handleSelection(undefined, undefined, undefined, cadences);
      setSelectedCadenceRange("");
    }
    // Range radio button selected
    else if (
      type.endsWith(FILTER_RAGNGE) &&
      fromOrTo !== FILTER_FROM &&
      fromOrTo !== FILTER_TO
    ) {
      clearPreviousSelectedCadence(type);
      setSelectedCadenceRange(type);
      let cadenceList = getSelectedCadences(
        codeText,
        selectedCadenceRangeFrom,
        selectedCadenceRangeTo
      );
      handleSelection(undefined, undefined, undefined, cadenceList);
    }
    // From in range group selected
    else if (type.endsWith(FILTER_RAGNGE) && fromOrTo === FILTER_FROM) {
      clearPreviousSelectedCadence(type);
      let rangeFrom = codeText;
      setSelectedCadenceRangeFrom(rangeFrom);
      if (selectedCadenceRangeTo) {
        let cadenceList = getSelectedCadences(
          type,
          rangeFrom,
          selectedCadenceRangeTo
        );
        handleSelection(undefined, undefined, undefined, cadenceList);
      }
      // If only From is selected but To hasn't, need to clear the previous cadences
      else if (selectedCadences) {
        const cadences: string[] = [];
        handleSelection(undefined, undefined, undefined, cadences);
      }
      setSelectedCadenceRange(type);
    }
    // To in range group selected
    else if (type.endsWith(FILTER_RAGNGE) && fromOrTo === FILTER_TO) {
      clearPreviousSelectedCadence(type);
      let rangeTo = codeText;
      setSelectedCadenceRangeTo(rangeTo);
      if (selectedCadenceRangeFrom) {
        let cadenceList = getSelectedCadences(
          type,
          selectedCadenceRangeFrom,
          rangeTo
        );
        handleSelection(undefined, undefined, undefined, cadenceList);
      }
      // If only To is selected but From hasn't, need to clear the previous cadences
      else if (selectedCadences) {
        const cadences: string[] = [];
        handleSelection(undefined, undefined, undefined, cadences);
      }
      setSelectedCadenceRange(type);
    }
  };

  // When a new range is selected, check to see if the new range matches with previously-selected.
  // For example, if previously selected lts-range (from/to has value) and later select a stable-range,
  // need to clear out the previous selectedCadenceRangeFrom and selectedCadenceRangeTo.
  const clearPreviousSelectedCadence = (type?: string) => {
    var countOfDotsInFrom = (selectedCadenceRangeFrom?.match(/\./g) || [])
      .length;
    var countOfDotsInTo = (selectedCadenceRangeTo?.match(/\./g) || []).length;

    var isPreviousFromLts = false;
    var isPreviousToLts = false;
    if (countOfDotsInFrom === 1 && selectedCadenceRangeFrom?.endsWith("_LTS")) {
      //The new format of LTS
      isPreviousFromLts = true;
    } else if (
      countOfDotsInFrom === 1 &&
      !selectedCadenceRangeFrom?.endsWith("_LTS")
    ) {
      //The old format of LTS
      isPreviousFromLts = false;
    } else {
      isPreviousFromLts = false;
    }

    if (countOfDotsInTo === 1 && selectedCadenceRangeTo?.endsWith("_LTS")) {
      //The new format of LTS
      isPreviousToLts = true;
    } else if (
      countOfDotsInTo === 1 &&
      !selectedCadenceRangeTo?.endsWith("_LTS")
    ) {
      //The old format of LTS
      isPreviousToLts = false;
    } else {
      isPreviousToLts = false;
    }

    // Previously selected a LTS from, now select a STABLE
    if (type?.startsWith(FILTER_STABLE) && isPreviousFromLts) {
      setSelectedCadenceRangeFrom("");
    }
    // Previously selected a LTS to, now select a STABLE
    if (type?.startsWith(FILTER_STABLE) && isPreviousToLts) {
      setSelectedCadenceRangeTo("");
    }
    // Previously selected a STABLE from, now select a LTS
    if (type?.startsWith(FILTER_LTS) && countOfDotsInFrom === 2) {
      setSelectedCadenceRangeFrom("");
    }
    // Previously selected a STABLE to, now select a STABLE
    if (type?.startsWith(FILTER_LTS) && countOfDotsInTo === 2) {
      setSelectedCadenceRangeTo("");
    }
  };

  const getSelectedCadences = (type?: string, from?: string, to?: string) => {
    let list = Array<string>();
    if (type === FILTER_LTS || type === FILTER_LTS_RANGE) {
      list = getVersionRangeList(
        wrapperData ? wrapperData.ltsVersions : [],
        from ? from : "",
        to ? to : ""
      );
    } else if (type === FILTER_STABLE || type === FILTER_STABLE_RANGE) {
      list = getVersionRangeList(
        wrapperData ? wrapperData.stableVersions : [],
        from ? from : "",
        to ? to : ""
      );
    }
    return list;
  };

  const getVersionRangeList = (
    versionList: Cadence[],
    from: string,
    to: string
  ) => {
    let versionCodeTextList = Array<string>();
    versionList.forEach((version) => {
      versionCodeTextList.push(version.codeText);
    });
    let indexFrom = versionCodeTextList.indexOf(from);
    let indexTo = versionCodeTextList.indexOf(to);
    let versionRangeList = versionCodeTextList.slice(
      indexFrom < indexTo ? indexFrom : indexTo,
      indexFrom < indexTo ? indexTo + 1 : indexFrom + 1
    );
    return versionRangeList;
  };

  const applyFilters = () => {
    if (showSmallDeviceFilterContainer) {
      setShowSmallDeviceFilterContainer(false);
    }

    setFilterApplied(true);
    handleSelection(
      selectedOfferings,
      selectedCategories,
      selectedReleaseNoteTypes,
      selectedCadences
    );

    // Build FilterDto for retrieval, retrieve release notes and send data back to the parent
    let filterDto = {
      cadences: selectedCadences,
      offerings: selectedOfferings,
      categories: selectedCategories,
      releaseNoteTypes: selectedReleaseNoteTypes,
      filterByVersionOnly: false,
    };
    handleFilters(filterDto);
  };

  const resetFilters = () => {
    handleResetFilters();
  };

  const keypressHandler = (e: React.KeyboardEvent) => {
    //13: Enter key; 32: Space key
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleExpandFilter();
      e.preventDefault();
    }
  };

  //https://stackoverflow.com/questions/37568550/react-prevent-event-trigger-on-parent-from-child
  //React has a cascade reaction when child click event happens it would trigger parent's click event.
  //To stop propagation in parent's level, check the clicked element's id; invoke event accordingly.
  const toggleFilter = (e: React.MouseEvent) => {
    let target = e.target as HTMLElement;
    if (
      target.id === "outerDiv" ||
      target.id === "innerDiv" ||
      target.id === "imgExpand" ||
      target.id === "imgCollapse" ||
      target.id === "spanFilter"
    ) {
      handleExpandFilter();
    } else {
      e.stopPropagation();
    }
  };

  return (
    <>
      {
        // Desktop mode
        !isSmallDevice ? (
          <div
            className={`${styles.filterToggle} ${styles.filterToggleMargin}`}
          >
            <Card>
              <div
                className={styles.toggleHeader}
                onKeyDown={keypressHandler}
                onClick={toggleFilter}
                tabIndex={0}
              >
                <div
                  className={`${styles.filterBanner} ${styles.filterBannerPadding}`}
                  onClick={toggleFilter}
                  id="innerDiv"
                  role="button"
                >
                  <span>
                    {expandFilter ? (
                      <img
                        id="imgExpand"
                        className={styles.toggleIcon}
                        src="images/arrowExpanded.svg"
                        onClick={toggleFilter}
                        alt={t(
                          "releasenotes-gui-icu.releasenotes.filter_expand.msg"
                        )}
                      />
                    ) : (
                      <img
                        id="imgCollapse"
                        className={styles.toggleIcon}
                        src="images/arrowCollapsed.svg"
                        onClick={toggleFilter}
                        alt={t(
                          "releasenotes-gui-icu.releasenotes.filter_collapse.msg"
                        )}
                      />
                    )}
                  </span>
                  <span
                    id="spanFilter"
                    className={styles.toggleTitle}
                    onClick={toggleFilter}
                    role="button"
                  >
                    {t("releasenotes-gui-icu.releasenotes.filter_title.msg")}
                  </span>
                </div>
              </div>
              {expandFilter ? (
                <div
                  className={styles.toggleCollapse}
                  onKeyDown={keypressHandler}
                >
                  <SelectedReleaseNoteTypesContext.Provider
                    value={selectedReleaseNoteTypes}
                  >
                    <Card>
                      <Card.Body className={styles.toggleCollapseCard}>
                        <FilterOfferingCategoryPanel
                          paramCadence={paramCadence}
                          wrapperData={wrapperData}
                          selectAll={selectAll}
                          selectedOfferings={offerings}
                          selectedCategories={categories}
                          handleChange={handleChange}
                        ></FilterOfferingCategoryPanel>
                        <VerticalDivider></VerticalDivider>
                        <FilterCadencePanel
                          paramCadence={paramCadence}
                          wrapperData={wrapperData}
                          selectedCadences={selectedCadences}
                          handleChange={handleChange}
                          selectedCadenceRange={selectedCadenceRange}
                          selectedCadenceRangeFrom={selectedCadenceRangeFrom}
                          selectedCadenceRangeTo={selectedCadenceRangeTo}
                        ></FilterCadencePanel>
                        <div>
                          <HorizontalDivider />
                        </div>
                        <FilterReleaseNoteTypePanel
                          paramCadence={paramCadence}
                          wrapperData={wrapperData}
                          selectedReleaseNotesTypes={selectedReleaseNoteTypes}
                          handleChange={handleChange}
                        ></FilterReleaseNoteTypePanel>
                        <FilterApplyResetPanel
                          applyFilters={applyFilters}
                          resetFilters={resetFilters}
                          filterApplied={filterApplied}
                          releaseNotes={updatedReleaseNotes}
                          selectedOfferings={offerings}
                          selectedCategories={categories}
                          selectedCadences={selectedCadences}
                          selectedReleaseNoteTypes={selectedReleaseNoteTypes}
                        ></FilterApplyResetPanel>
                      </Card.Body>
                    </Card>
                  </SelectedReleaseNoteTypesContext.Provider>
                </div>
              ) : (
                ""
              )}
            </Card>
          </div>
        ) : (
          // On small device: a button to open full screen filter container
          <div className={styles.smallDeviceFilterDiv}>
            <button
              type="button"
              className={styles.smallDeviceFilterButton}
              onClick={() => setShowSmallDeviceFilterContainer(true)}
              aria-label={t(
                "releasenotes-gui-icu.releasenotes.filter_title.msg"
              )}
            >
              {t("releasenotes-gui-icu.releasenotes.filter_title.msg")}
            </button>
          </div>
        )
      }

      {/* Small device:  full screen filter container */}
      {showSmallDeviceFilterContainer ? (
        <SelectedCadenceRangeContext.Provider value={selectedCadenceRange}>
          <SelectedReleaseNoteTypesContext.Provider
            value={selectedReleaseNoteTypes}
          >
            <SelectedCadenceRangeFromContext.Provider
              value={selectedCadenceRangeFrom}
            >
              <SelectedCadenceRangeToContext.Provider
                value={selectedCadenceRangeTo}
              >
                <FilterToggleSmallDevice
                  paramCadence={paramCadence}
                  wrapperData={wrapperData}
                  releaseNotes={releaseNotes}
                  selectedOfferings={selectedOfferings}
                  selectedCategories={selectedCategories}
                  selectedReleaseNoteTypes={selectedReleaseNoteTypes}
                  selectedCadences={selectedCadences}
                  selectAll={selectAll}
                  handleChange={handleChange}
                  applyFilters={applyFilters}
                  resetFilters={resetFilters}
                  filterApplied={filterApplied}
                  setShowSmallDeviceFilterContainer={
                    setShowSmallDeviceFilterContainer
                  }
                ></FilterToggleSmallDevice>
              </SelectedCadenceRangeToContext.Provider>
            </SelectedCadenceRangeFromContext.Provider>
          </SelectedReleaseNoteTypesContext.Provider>
        </SelectedCadenceRangeContext.Provider>
      ) : (
        ""
      )}
    </>
  );
};

export default FilterToggle;
