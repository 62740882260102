export const LANGUAGE_ENGLISH = "en";

export const OFFERING = "offering";
export const CATEGORY = "category";
export const CATEGORY_PROGRAMMING = "programming";
export const CATEGORY_DEPLOYMENT = "deployment";
export const CATEGORY_ADMINISTRATION = "administration";

export const RELEASE_NOTE_TYPE = "releaseNoteType";
export const TYPE_WHATSNEW = "whatsNew";
export const TYPE_DEPRECATED = "deprecated";
export const TYPE_CRITICAL = "critical";
export const TYPE_DEPLOYMENTNOTES = "deploymentNotes";
export const CHECKBOX_ID_WHATSNEW = "releaseNoteType-whatsNew";

export const MEDIA_DEVICE_SMALL = "(max-width: 767px)";

export const MY_SAS_LINK = "https://my.sas.com";

export const FILTER_LTS = "lts";
export const FILTER_STABLE = "stable";
export const FILTER_LTS_INDIVIDUAL = "lts-individual";
export const FILTER_STABLE_INDIVIDUAL = "stable-individual";
export const FILTER_LTS_RANGE = "lts-range";
export const FILTER_STABLE_RANGE = "stable-range";
export const FILTER_RAGNGE = "range";
export const FILTER_FROM = "from";
export const FILTER_TO = "to";

export const OFFERINGS = "offerings";
export const VERSIONS = "versions";
export const TYPE = "type";

export const PRODUCT_DISPLAY_LIMIT = 5;
export const STRING_LENGTH = 28;

export const HEIGHT_OFFSET_MESSAGE_SHOWN = 320;
export const HEIGHT_OFFSET_MESSAGE_NOT_SHOWN = 236;
