import React, { useState, useEffect } from "react";

//Node Modules
//import { useTranslation } from 'react-i18next'

//Components
import Checkbox from "components/Checkbox";

//Types
import { WrapperData } from "types/wrapperData";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./FilterOfferingList.module.scss";

interface Props {
  wrapperData?: WrapperData;
  selectedOfferings: string[];
  handleChange: (codeText: string, type: string) => void;
}

const FilterOfferingList = ({
  wrapperData,
  selectedOfferings,
  handleChange,
}: Props) => {
  const isSmallDevice = useIsSmallDevice();
  const [offerings, setOfferings] = useState(selectedOfferings);
  useEffect(() => {
    setOfferings(selectedOfferings);
  }, [selectedOfferings]);

  const findSelectedItem = (codeText: string): boolean => {
    return offerings.includes(codeText);
  };

  return (
    <>
      {wrapperData?.offerings.map((offering) => {
        return (
          <div
            key={offering.codeText}
            className={
              isSmallDevice
                ? styles.smallDeviceOfferingItem
                : styles.offeringItem
            }
          >
            <Checkbox
              id={offering.codeText}
              label={offering.displayValue}
              title={offering.displayValue}
              isChecked={findSelectedItem(offering.codeText)}
              handleChange={handleChange}
              type="offering"
            />
          </div>
        );
      })}
    </>
  );
};

export default FilterOfferingList;
