import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";

//Components
import FilterTypeList from "components/FilterTypeList";

//Types
import { WrapperData } from "types/wrapperData";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./FilterReleaseNoteTypePanel.module.scss";

interface Props {
  paramCadence?: string;
  wrapperData?: WrapperData;
  selectedReleaseNotesTypes: string[];
  handleChange: (codeText: string, type: string, fromOrTo?: string) => void;
}

const FilterReleaseNoteTypePanel = ({
  paramCadence,
  wrapperData,
  selectedReleaseNotesTypes,
  handleChange,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  return (
    <>
      <Card className={isSmallDevice ? styles.smallDevicePanel : styles.panel}>
        <div className={styles.sectionTitle}>
          {t("releasenotes-gui-icu.releasenotes.filter_type.msg")}
        </div>
        <div id="divTypes" className={styles.releaseNoteTypeListDiv}>
          <FilterTypeList
            paramCadence={paramCadence}
            wrapperData={wrapperData}
            selectedReleaseNotesTypes={selectedReleaseNotesTypes}
            handleChange={handleChange}
          ></FilterTypeList>
        </div>
      </Card>
    </>
  );
};

export default FilterReleaseNoteTypePanel;
