import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import Checkbox from "components/Checkbox";

//Types
import { WrapperData } from "types/wrapperData";

//Hooks
import { useIsSmallDevice } from "hooks";

//Util
import {
  CATEGORY,
  CATEGORY_PROGRAMMING,
  CATEGORY_DEPLOYMENT,
  CATEGORY_ADMINISTRATION,
} from "util/constants";

//Styles
import styles from "./FilterCategoryList.module.scss";

interface Props {
  paramCadence?: string;
  wrapperData?: WrapperData;
  selectedCategories: string[];
  handleChange: (codeText: string, type: string) => void;
}

const FilterCategoryList = ({
  wrapperData,
  selectedCategories,
  handleChange,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  const findSelectedItem = (codeText: string): boolean => {
    return selectedCategories.includes(codeText);
  };

  const getLabel = (codeText: string): string => {
    let label = "";
    if (codeText === CATEGORY_PROGRAMMING) {
      label = t(
        "releasenotes-gui-icu.releasenotes.filter_category_programming.msg",
      );
    }
    if (codeText === CATEGORY_DEPLOYMENT) {
      label = t(
        "releasenotes-gui-icu.releasenotes.filter_category_deployment.msg",
      );
    }
    if (codeText === CATEGORY_ADMINISTRATION) {
      label = t(
        "releasenotes-gui-icu.releasenotes.filter_category_administration.msg",
      );
    }
    return label;
  };

  return (
    <>
      <div className="styles.categoryList">
        {wrapperData?.categories.map((category) => {
          return (
            <div
              key={category.codeText}
              className={
                isSmallDevice
                  ? styles.smallDeviceCategoryItem
                  : styles.categoryItem
              }
            >
              <Checkbox
                id={category.codeText}
                label={getLabel(category.codeText)}
                title={getLabel(category.codeText)}
                isChecked={findSelectedItem(category.codeText)}
                type={CATEGORY}
                handleChange={handleChange}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FilterCategoryList;
