import React from "react";

//Node Modules
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

//Components
import { CloseIcon } from "components/SASIcon";

//Util
import { OFFERINGS, VERSIONS, TYPE } from "util/constants";

//Styles
import styles from "./FilterOptionSelectModal.module.scss";

interface Props {
  show: boolean;
  onHide: () => void;
  handleTabSelection: (option: string) => void;
}

const FilterOptionSelectModal = ({
  show,
  onHide,
  handleTabSelection,
}: Props) => {
  const { t } = useTranslation();

  const handleClick = (option: string) => {
    handleTabSelection(option);
    onHide();
  };

  return (
    <>
      <div className={styles.modalWindow}>
        <Modal
          className={styles.modalWindow}
          show={show}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          animation={false}
          dialogClassName={styles.modalWindow}
        >
          <Modal.Header className={styles.modalHeader}>
            <Modal.Title className={styles.modalTitle}>
              {t("releasenotes-gui-icu.releasenotes.filter_version_select.msg")}
            </Modal.Title>
            <button
              type="button"
              className={`${styles.close}`}
              onClick={onHide}
              aria-label={t("releasenotes-gui-icu.releasenotes.close.msg")}
            >
              <CloseIcon />
            </button>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            <Container fluid="md">
              <Row
                className={styles.row}
                onClick={() => handleClick(OFFERINGS)}
              >
                {t("releasenotes-gui-icu.releasenotes.filter_offerings.msg")}
              </Row>
              <Row className={styles.row} onClick={() => handleClick(VERSIONS)}>
                {t("releasenotes-gui-icu.releasenotes.filter_versions.msg")}
              </Row>
              <Row className={styles.row} onClick={() => handleClick(TYPE)}>
                {t("releasenotes-gui-icu.releasenotes.filter_type.msg")}
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default FilterOptionSelectModal;
