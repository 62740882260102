import React, { useState, useRef, useEffect } from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import FilterOfferingCategoryPanel from "components/FilterOfferingCategoryPanel";
import FilterCadenceReleaseNoteTypePanel from "components/FilterCadencePanel";
import FilterReleaseNoteTypePanel from "components/FilterReleaseNoteTypePanel";
import FilterApplyResetPanel from "components/FilterApplyResetPanel";
import MessageBox from "components/MessageBox";
import { CloseIcon, TopIcon } from "components/SASIcon";

//Types
import { WrapperData } from "types/wrapperData";
import { FilterResultWrapper } from "types/filterResultWrapper";

//Util
import {
  OFFERINGS,
  VERSIONS,
  TYPE,
  HEIGHT_OFFSET_MESSAGE_SHOWN,
  HEIGHT_OFFSET_MESSAGE_NOT_SHOWN,
} from "util/constants";

//Styles
import styles from "./FilterToggleSmallDevice.module.scss";
import FilterOptionSelectModal from "components/FilterOptionSelectModal";

interface Props {
  paramCadence?: string;
  wrapperData?: WrapperData;
  releaseNotes?: FilterResultWrapper[];
  selectedOfferings: string[];
  selectedCategories: string[];
  selectedReleaseNoteTypes: string[];
  selectedCadences: string[];
  selectedCadenceRange?: string;
  selectedCadenceRangeFrom?: string;
  selectedCadenceRangeTo?: string;
  selectAll: (select: boolean) => void;
  handleChange: (codeText: string, type: string, fromOrTo?: string) => void;
  applyFilters: () => void;
  resetFilters: () => void;
  filterApplied?: boolean;
  setShowSmallDeviceFilterContainer: (show: boolean) => void;
}

const FilterToggleSmallDevice = ({
  paramCadence,
  wrapperData,
  releaseNotes,
  selectedOfferings,
  selectedCategories,
  selectedReleaseNoteTypes,
  selectedCadences,
  selectedCadenceRange,
  selectedCadenceRangeFrom,
  selectedCadenceRangeTo,
  selectAll,
  handleChange,
  applyFilters,
  resetFilters,
  filterApplied,
  setShowSmallDeviceFilterContainer,
}: Props) => {
  const { t } = useTranslation();
  const [showMessageBox, setShowMessageBox] = useState<boolean>(
    releaseNotes?.length === 0 && !filterApplied ? true : false,
  );
  const [showDivOfferings, setShowDivOfferings] = useState(true);
  const [showOptionSelectModal, setShowOptionSelectModal] = useState(false);
  const [showDivVersions, setShowDivVersions] = useState(false);
  const [showDivTypes, setShowDivTypes] = useState(false);
  const [scrollVisible, setScrollVisible] = useState(false);
  const offeringContainerElement = useRef<HTMLDivElement>(null);
  const versionContainerElement = useRef<HTMLDivElement>(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  function handleScroll(event: React.UIEvent) {
    const scrollTop = (event.target as HTMLElement).scrollTop;

    setScrollVisible(scrollTop >= 200);
  }
  function handleScrollToTop() {
    if (showDivOfferings) {
      let el = document.getElementById("offeringContainer");
      if (el) {
        el.scrollTop = 0;
      }
      setScrollVisible(false);
    } else if (showDivVersions) {
      let el = document.getElementById("versionContainer");
      if (el) {
        el.scrollTop = 0;
      }
      setScrollVisible(false);
    }
  }

  const handleTabSelection = (option: string) => {
    switch (option) {
      case OFFERINGS: {
        setShowDivOfferings(true);
        setShowDivVersions(false);
        setShowDivTypes(false);
        break;
      }
      case VERSIONS: {
        setShowDivOfferings(false);
        setShowDivVersions(true);
        setShowDivTypes(false);
        break;
      }
      case TYPE: {
        setShowDivOfferings(false);
        setShowDivVersions(false);
        setShowDivTypes(true);
        break;
      }
      default: {
      }
    }
  };

  // set max-height dynamically depending on the viewport height
  // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
  useEffect(() => {
    let offeringContainer = document.getElementById("offeringContainer");
    let versionContainer = document.getElementById("versionContainer");
    let newHeight =
      window.innerHeight -
      (showMessageBox
        ? HEIGHT_OFFSET_MESSAGE_SHOWN
        : HEIGHT_OFFSET_MESSAGE_NOT_SHOWN);
    if (window.innerHeight !== newHeight) {
      offeringContainer?.style.setProperty("max-height", `${newHeight}px`);
      versionContainer?.style.setProperty("max-height", `${newHeight}px`);
      window.addEventListener("resize", (e) => {
        setWindowHeight(window.innerHeight);
      });
    }
  }, [showMessageBox, windowHeight]);

  return (
    <>
      <div className={styles.smallDeviceFilterContainer}>
        <div className={styles.headerDiv}>
          <label className={styles.header}>
            {t("releasenotes-gui-icu.releasenotes.filter_title.msg")}
          </label>
          <button
            type="button"
            className={styles.close}
            onClick={() => setShowSmallDeviceFilterContainer(false)}
            aria-label={t("releasenotes-gui-icu.modal.close_button.msg")}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={styles.filterContainer}>
          <MessageBox
            showMessageBox={showMessageBox}
            setShowMessageBox={setShowMessageBox}
          ></MessageBox>
          <div className={styles.divFilterTabs}>
            <button
              type="button"
              className={`${styles.buttonFilter} ${styles.buttonOffering} ${
                showDivOfferings ? styles.highlight : ""
              }`}
              onClick={() => handleTabSelection(OFFERINGS)}
              onTouchStart={() => handleTabSelection(OFFERINGS)}
              aria-label={t(
                "releasenotes-gui-icu.releasenotes.filter_offerings.msg",
              )}
            >
              {t("releasenotes-gui-icu.releasenotes.filter_offerings.msg")}
            </button>
            <button
              type="button"
              className={`${styles.buttonFilter} ${styles.buttonVersion} ${
                showDivVersions ? styles.highlight : ""
              }`}
              onClick={() => handleTabSelection(VERSIONS)}
              onTouchStart={() => handleTabSelection(VERSIONS)}
              onTouchMove={() => alert("version touchmove")}
              onTouchEnd={() => handleTabSelection(VERSIONS)}
              aria-label={t(
                "releasenotes-gui-icu.releasenotes.filter_versions.msg",
              )}
            >
              {t("releasenotes-gui-icu.releasenotes.filter_versions.msg")}
            </button>
            <button
              type="button"
              className={`${styles.buttonFilter} ${styles.buttonType} ${
                showDivTypes ? styles.highlight : ""
              }`}
              onClick={() => handleTabSelection(TYPE)}
              onTouchStart={() => handleTabSelection(TYPE)}
              aria-label={t(
                "releasenotes-gui-icu.releasenotes.filter_type.msg",
              )}
            >
              {t("releasenotes-gui-icu.releasenotes.filter_type.msg")}
            </button>
          </div>
          <div
            id="offeringContainer"
            ref={offeringContainerElement}
            onScroll={handleScroll}
            className={`${styles.divScroll} `}
          >
            <div
              className={`${styles.divOfferings} ${
                showDivOfferings ? "" : styles.divHide
              }`}
            >
              <FilterOfferingCategoryPanel
                paramCadence={paramCadence}
                wrapperData={wrapperData}
                selectAll={selectAll}
                selectedOfferings={selectedOfferings}
                selectedCategories={selectedCategories}
                handleChange={handleChange}
              ></FilterOfferingCategoryPanel>
            </div>
          </div>
          <div
            id="versionContainer"
            ref={versionContainerElement}
            onScroll={handleScroll}
            className={`${styles.divScroll}`}
          >
            <div
              id="divVersions"
              className={`${styles.divVersions} ${
                showDivVersions ? "" : styles.divHide
              }`}
            >
              <FilterCadenceReleaseNoteTypePanel
                paramCadence={paramCadence}
                wrapperData={wrapperData}
                selectedCadences={selectedCadences}
                handleChange={handleChange}
                selectedCadenceRange={selectedCadenceRange}
                selectedCadenceRangeFrom={selectedCadenceRangeFrom}
                selectedCadenceRangeTo={selectedCadenceRangeTo}
              ></FilterCadenceReleaseNoteTypePanel>
            </div>
            <div
              id="divTypes"
              className={`${styles.divTypes} ${
                showDivTypes ? "" : styles.divHide
              }`}
            >
              <FilterReleaseNoteTypePanel
                paramCadence={paramCadence}
                wrapperData={wrapperData}
                selectedReleaseNotesTypes={selectedReleaseNoteTypes}
                handleChange={handleChange}
              ></FilterReleaseNoteTypePanel>
            </div>
            <button
              className={`${styles.scrollToTop} ${styles.paddingBottom} ${
                scrollVisible ? styles.visible : styles.hidden
              }`}
              title={t("releasenotes-gui-icu.releasenotes.scroll_to_top.msg")}
              aria-label={t(
                "releasenotes-gui-icu.releasenotes.scroll_to_top.msg",
              )}
              onClick={handleScrollToTop}
            >
              <TopIcon />
            </button>
          </div>
          <div className={styles.footer}>
            <FilterApplyResetPanel
              applyFilters={applyFilters}
              resetFilters={resetFilters}
              releaseNotes={releaseNotes}
              selectedOfferings={selectedOfferings}
              selectedCategories={selectedCategories}
              selectedCadences={selectedCadences}
              selectedReleaseNoteTypes={selectedReleaseNoteTypes}
            ></FilterApplyResetPanel>
          </div>
          <div className="invisible">{windowHeight}</div>
        </div>
        <FilterOptionSelectModal
          show={showOptionSelectModal}
          onHide={() => setShowOptionSelectModal(false)}
          handleTabSelection={handleTabSelection}
        ></FilterOptionSelectModal>
      </div>
    </>
  );
};

export default FilterToggleSmallDevice;
