//import React, { useState } from 'react'

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import Checkbox from "components/Checkbox";

//Types
import { WrapperData } from "types/wrapperData";

//Hooks
import { useIsSmallDevice } from "hooks";

//Util
import {
  RELEASE_NOTE_TYPE,
  TYPE_WHATSNEW,
  TYPE_DEPRECATED,
  TYPE_DEPLOYMENTNOTES,
} from "util/constants";

//Styles
import styles from "./FilterTypeList.module.scss";

interface Props {
  paramCadence?: string;
  wrapperData?: WrapperData;
  selectedReleaseNotesTypes: string[];
  handleChange: (codeText: string, type: string) => void;
}

const FilterTypeList = ({
  wrapperData,
  selectedReleaseNotesTypes,
  handleChange,
}: Props) => {
  const isSmallDevice = useIsSmallDevice();
  const { t } = useTranslation();

  // If paramCadence is passed, by default check all release note types
  const findSelectedItem = (codeText: string): boolean => {
    return selectedReleaseNotesTypes.includes(codeText);
  };

  const getLabel = (codeText: string): string => {
    let label = "";
    if (codeText === TYPE_WHATSNEW) {
      label = t("releasenotes-gui-icu.releasenotes.filter_type_whatsnew.msg");
    }
    if (codeText === TYPE_DEPRECATED) {
      label = t("releasenotes-gui-icu.releasenotes.filter_type_critical_and_deprecated.msg");
    }
    if (codeText === TYPE_DEPLOYMENTNOTES) {
      label = t(
        "releasenotes-gui-icu.releasenotes.filter_type_deployment_note.msg"
      );
    }
    return label;
  };

  return (
    <>
      <div className={styles.typeListDiv}>
        {wrapperData?.releaseNoteTypes.map((item) => {
          return (
            <div
              key={item.codeText}
              className={`${isSmallDevice ? styles.smallDeviceItem : styles.item} ${item.codeText === TYPE_DEPRECATED ? styles.indent : ""}`}
            >
              <Checkbox
                id={item.codeText}
                label={getLabel(item.codeText)}
                title={getLabel(item.codeText)}
                isChecked={findSelectedItem(item.codeText)}
                type={RELEASE_NOTE_TYPE}
                handleChange={handleChange}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FilterTypeList;
